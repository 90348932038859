import { StrictMode, useEffect, useRef } from 'react'
import React from 'react';
import {Canvas} from '@react-three/fiber'
import { Perf } from 'r3f-perf';
import { OrbitControls } from '@react-three/drei';
import Experience from './Experience.js'
import { Leva } from 'leva';
import Measurements from './clickTools/Measurements.js';
import Info from './clickTools/Info.js';
import CameraControl from './clickTools/CameraControl.js';
import ColorChange from './clickTools/ColorChange.js';

export default function App()
{   

    useEffect(()=>{
        console.log('rendered')
    })

    const ref = useRef()

    return (
        <>
            <Leva />        
            <StrictMode>
                <Canvas flat shadows gl={{ antialias: true }} camera={{ position: [0, 7000, 0], near: 0.5, far: 10000, fov: 35 }}>
                    <Perf  position="top-left" />
                    <OrbitControls makeDefault ref={ref}/>                    
                    <Experience/>    
                    <CameraControl controls={ref} />                   
                </Canvas>
            </StrictMode>
            <Measurements />
            <Info/>
            <ColorChange/>

        </>
    )
}