import { create } from "zustand";

export const useClickStore =  create((set)=>({
    clickType:'pan',
    setClickType:(clickType_)=>set({clickType:clickType_}),
    measure_points:[],
    setClickParams:(point,object)=>{
        set((state)=>({
            objectDetails:(state.clickType==='info' && object.userData.displayData)?object.userData.displayData:'',
            objectDetailsPosition:(state.clickType==='info' && object.userData.displayData)?point:null,
            objectDetailsObject:(state.clickType==='info' && object.userData.displayData)?object:null,
            measure_points:(state.clickType==='distance' && state.measure_points.length<2)?[...state.measure_points,point]:(state.clickType==='distance'? [point]:[]),
            zoomPosition:(state.clickType==='zoom')?point:null,
            colorMaterial:(state.clickType==='color')?object.material:null,
            currentObjectName:object.name
        }))
    },
    objectDetails:'',
    objectDetailsPosition:null,
    objectDetailsObject:null,
    zoomPosition:null,
    colorMaterial:null,
    currentObjectName:null,
    zoomToExtent:false,
    setZoomToExtent:(z)=>set({zoomToExtent:z}),

}));

export const useInforStore = create((set)=>({
    info:'...',
    setInfo:(info_)=>set({info:info_})
}));

