import React from 'react'
import SabiModels from './components/SabiModels.js'
import { useClickStore, useInforStore } from './store/clickStore';
import { useShallow } from 'zustand/react/shallow'
import MeasureLines from './clickTools/MeasureLines.js'
import ObjectDetails from './clickTools/ObjectDetails.js';

export default function Experience()
{     
    const constants_ = [-90741.234,-2255027.981,880]

    const {setInfo} =    useInforStore(useShallow((state)=>({
        setInfo:state.setInfo
    })))

    const {setClickParams} = useClickStore(useShallow((state)=>({        
        setClickParams:state.setClickParams
    })))

    console.log('Experience rerendered')
    const manage_click=(e)=>{
        console.log(e.object)
        setInfo(e.object?e.object.name+`   coords:X:${(e.point.x+constants_[0]).toFixed(1)} Y:${(e.point.z+constants_[1]).toFixed(1)} Z:${(e.point.y+constants_[2]).toFixed(1)}`:'...')
        setClickParams(e.point,e.object)
        e.stopPropagation()
    }
    
    return <>    
        <directionalLight position={[1,200,-300]} intensity={2.5}/>
        <directionalLight position={[1,200,300]} intensity={2.5}/>
        <ambientLight intensity={1.5}/> 
        <group onClick={(e)=>{ manage_click(e)} } >
            <MeasureLines />
            <ObjectDetails/>
            <SabiModels/>
        </group>
    </>
}