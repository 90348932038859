import { useControls } from "leva"
import { ModelListPK } from "./Utils/ModelsList.js"
import { Suspense } from "react";
import ModelsSetup from "./Utils/ModelsSetup.js";
import AssayLoader from "./Utils/AssayLoader.js";


export default function SabiModels() {
    const name_ = 'SabiModels'
    const {visible} = useControls(name_, {visible:false});
    
    console.log('Sabi Models rerendered')
    return (
        <>
        
        { visible && 
            <group>
                <AssayLoader/>
               {ModelListPK.map((model) => (
                    <Suspense fallback={null} key={model.name}>
                        <ModelsSetup group_={name_} name_={model.name} models={model.models} />                        
                    </Suspense>
                ))}
            </group>
            
        }
        </>
    )
}
