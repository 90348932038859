import React, { useEffect, useState } from 'react'
import { useClickStore } from '../store/clickStore'
import { Html } from '@react-three/drei'
import { useShallow } from 'zustand/react/shallow'
import * as THREE from 'three'
import { LineMaterial } from 'three/examples/jsm/Addons.js'

export default function ObjectDetails() {

    const {objectDetails,objectDetailsPosition,objectDetailsObject,clickType} = useClickStore(useShallow((state)=>({
        objectDetails:state.objectDetails,
        objectDetailsPosition:state.objectDetailsPosition,
        objectDetailsObject:state.objectDetailsObject,
        clickType:state.clickType,
    })))

    const [previousObject, setPreviousObject] = useState(null);
    const [originalMaterial, setOriginalMaterial] = useState(null);

    const yellowMaterial = new THREE.MeshBasicMaterial({ color: "#ffff00" });

    useEffect(() => {
        if (objectDetailsObject) {
            // Revert the material of the previous object
            if (previousObject && previousObject !== objectDetailsObject) {
                previousObject.material = originalMaterial;
            }

            // Update the previous object state
            setPreviousObject(objectDetailsObject);
            console.log(objectDetailsObject.material.isLineMaterial)

            
            setOriginalMaterial(objectDetailsObject.material);

            if(objectDetailsObject.material && !(objectDetailsObject.material.isLineMaterial)){
                // Store the original material of the current object
                if (objectDetailsObject.material !== yellowMaterial) {
                    // Assign the yellow material to the current object
                    objectDetailsObject.material = yellowMaterial;
                }
            }else{
                objectDetailsObject.material.color.set('#ffff00')
            }


            console.log(originalMaterial)
        }
            
        
    }, [objectDetailsObject]);

    useEffect(()=>{
        if(clickType!=='info'){
            if (previousObject) {
                previousObject.material = originalMaterial;
            }
        }
    },[clickType])

    const rows = Object.entries(objectDetails).map(([key, value]) => (
        <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
        </tr>
    ));

  return (
    <group>
        {clickType =='info' && objectDetailsObject&&
            <Html position={objectDetailsPosition}>
                <div className='objectDetails'>
                <text className='detailsHeader'>{objectDetails.Name}</text>
                <table  className='detailsTable'>
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Value</th>
                        </tr>
                    </thead>
                    <tbody  >
                        {rows}
                    </tbody>
                </table>
                </div>
            </Html>
        }
    </group>
  )
}
