export const ModelListPK = [
  
    {
        'group':'Surface',
        'name':'terrain',
        'models':
        [
            {name:'drone',url:'./models/gltf/SABILO31-transformed.glb'},
            {name:'google',url:'./models/gltf/TERRAIN-transformed.glb'},
        ]   

    },
    {
        'group':'Designs',
        'name':'Pits',
        'models':
        [
            {name:'PitOutline',url:'./models/gltf/pit_outline-transformed.glb'},
            {name:'PitOutline1',url:'./models/gltf/pit_outline1-transformed.glb'},
            {name:'Pit1',url:'./models/gltf/Pit01.gltf'},
            {name:'Pit2',url:'./models/gltf/PIT_170224-transformed.glb'},
            {name:'Pit3',url:'./models/gltf/PIT_170224_C-transformed.glb'},
            {name:'Pit4',url:'./models/gltf/PIT_270224-transformed.glb'},
            {name:'Pit5',url:'./models/gltf/PIT_110424-transformed.glb'},
            {name:'Road',url:'./models/gltf/haul_road.glb'},
        ]   

    },
    {
        'group':'Survey',
        'name':'Development',
        'models':
        [
            {name:'Trenches',url:'./models/gltf/TRENCHES.gltf'},
            {name:'Quary',url:'./models/gltf/QUARRY.gltf'},
            {name:'Shaft',url:'./models/gltf/SHAFT.glb'},
            {name:'Raises',url:'./models/gltf/RAISE-transformed.glb'},
            {name:'0L',url:'./models/gltf/0L_08052024-transformed.glb'},
            {name:'HL',url:'./models/gltf/HL.glb'},
            {name:'3L',url:'./models/gltf/3L.glb'},
            {name:'5L',url:'./models/gltf/5L.glb'},
        ]   

    },
    {
        'group':'Geology',
        'name':'Orebodies',
        'models':
        [
            {name:'Orebody',url:'./models/gltf/Orebody.glb'},
            {name:'Orebody01',url:'./models/gltf/Orebody01.glb'},
            ]   

    }
]